/* You can add global styles to this file, and also import other style files */
.flex{display: flex;}
.flex-row{flex-direction: row;}
.flex-col{flex-direction: column;}
.menu-link{color: rgba(255,255,255,.5);}
.menu-link:hover{color: #fff;}
.pointer{cursor: pointer;text-decoration: none;}
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif;padding-right:0 !important; }
.gris{color: #676a6c;}
.bo{display: inline-block;}
.fs4{font-size: 4px!important;}
.fs8{font-size: 8px!important;}
.fs9{font-size: 9px!important;}
.fs10{font-size: 10px!important;}
.fs11{font-size: 11px!important;}
.fs12{font-size: 12px!important;}
.fs13{font-size: 13px!important;}
.fs14{font-size: 14px!important;}
.fs15{font-size: 15px!important;}
.fs16{font-size: 16px!important;}
.fs18{font-size: 18px!important;}
.fs20{font-size: 20px!important;}
.fs30{font-size: 30px!important;}
.fs35{font-size: 35px!important;}
.fs40{font-size: 40px!important;}
.fs45{font-size: 45px!important;}
.w5{width: 5px;}
.w10{width: 10px;}
.w15{width: 15px;}
.w20{width: 20%;}
.w25{width: 25%;}
.w30{width: 30px;}
.fLeft{float: left;}
.fRight{float: right;}
.col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9 {float: left;padding:0 15px;}
.contenedor_tabla{position: relative;}
.float-right{float: right;}
.table{margin-bottom: 0;}
.vertical-middle{vertical-align: middle;display: inline-block;}
.cont_int_modal{max-height: 500px;overflow: auto;}
.fondoModalTitle{background: #1c435d;color: #fff;}
.titleTabla thead {font-size: 13px;background: #2d5b79!important;color: #fff;}
.label{border-radius:3px;margin: 0 0 10px;background-color: #d1dade;color: #5e5e5e;font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;font-size: 10px;font-weight: 600;padding: 3px 8px;text-shadow: none;}
.navbar-nav{justify-content: end;align-items: center;width: 100%;}
.dropdown-menu[data-bs-popper]{left: auto;right: 0;}
.tabla_resp{width: 100%;overflow: auto;}
.fondo_bco{background: #fff!important;}
.fondo_gris{background:#ebebeb!important}
.texto-gris{color: #686161;}
.txtDescripcion{
    color: #39393e !important;
    font-size: 10px !important;
    font-weight: 800!important;
}
/*Listado*/
.filtros{width: 18%;min-width: 150px;float: left;margin-right: 2%;max-width: 205px;}
.cont_collection{width:80%;float: left;}
.collection {display: grid;grid-template-columns: repeat(auto-fit, 270px);grid-auto-rows: 400px;grid-gap: 1.5rem;justify-content: center;width:100%;}
.articulo{margin: 25px auto;border: 1px solid #cccccc52;width: 270px;height: 385px;box-shadow: 5px 5px 10px #99999947;}
.imagen {width: 100%; height: 100%;}
.imagen-contenedor{align-content: center;margin-left: 10%;margin-right: 10%;margin-bottom: 15px;}
.nombre{overflow: hidden;min-height: 1.3em;max-height: 62px!important;color: #676a6c;font-size: 15px;text-align: left;}
.precio{overflow: hidden;min-height: 1.3em;color: #676a6c;font-size: 16px;font-weight: 400;}
.sku{text-align: left;font-size: 14px;color: #676a6c8f;font-weight: 600;}
.botonComprar{background-color: #ededf10d;border: 1px solid #ccc;width: 70%;height: 35px;font-size: 14px;font-weight: 400;color: #676a6c;}
.articulo{transition: transform 2s;}
.articulo:hover{border: solid 1px #676a6c;}
.articulo:hover .botonComprar {background:#676a6c;color: #fff;}
.botonComprar:hover {background:#676a6c;color: #fff;}
.collection{margin-bottom:30px;}
.cien{width: 100%;display: inline-block;position: relative;}
.cincuenta{width: 50%;float: left;}
.mAuto{margin:0 auto;}
/*.contenedor{ max-width: 1600px; margin: 0 auto; float: none;}*/
.mt-5{margin-top: 5rem;}
.mt-10{margin-top: 10px;}
.mt-20{margin-top: 20px;}
.mt-30{margin-top: 30px;}
.text-right{text-align: right!important;}
.mb-3-px{margin-bottom: 3rem!important;}
.widget {display: flex;align-content: space-between;padding: 15px;border-radius: 5px;justify-content: space-between;flex-direction: row;color: #fff;align-items: center;}
.widget span {font-size: 50px;}
.widget p {margin: 10px 0 0;text-align: right;font-size: 18px;}
.widget-rojo {background: #e65b5b;}
.texto-rojo-e{color: #e65b5b;}
.widget-azul {background: #595bd1;}
.texto-azul-e{color:  #595bd1;}
.widget-verde {background: #59c75d;}
.texto-verde-e{color: #59c75d;}
.widget-observaciones {background: #bed3d6;}
.text-azul{color:#174b99}
.breadcrumb {list-style: none;width: 100%;background-color: #efecec;padding: 10px;border-radius: 5px;margin-bottom: 35px;font-size: 13px;color: #686161;font-weight: 400;}
.tLeft{text-align: left;}
.tRight{text-align: right;}
.text-aceptado{color: #2cd139!important;}

.title{text-align: center;color: #09222c;}
.title2{text-align: center;color: #394c7a;}
html, body { height: 100%; 
}
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; 
}
 

.table-responsive{width:100%;max-height:600px;min-height:auto;overflow:auto;margin-bottom:50px;}
.table-responsive2{width:100%;margin-bottom:50px;}
.tabla_dialog{width: 100%;max-width: 700px;overflow: auto;background: #f8fafa;padding: 10px;}

@media (max-width: 991.98px){
.navbar .container, .navbar .container-fluid, .navbar .container-lg, .navbar .container-md, .navbar .container-sm, .navbar .container-xl {justify-content: right;} 

}
@media (min-width: 1500px){
    .container{max-width: 1480px;margin-bottom: 40px;}
}

@media (max-width: 1000px){
    .cont_collection {width: 70%;}
    .filtros{width: 28%;}
    
}

@media (max-width: 800px){
    .cont_collection {width: 55%;}
    .filtros{width: 48%;}
}
        
@media (max-width: 520px){
    .cont_collection {width: 100%;}
    .filtros{width: 100%;margin:0 0 20px;max-width: 100%;text-align: center;}
}
   
.tabla_resp .mat-form-field-appearance-fill .mat-form-field-flex{background: #fff;}   
.mat-form-field-underline{bottom: 0!important;}
.mat-form-field-wrapper {padding-bottom: 0!important;}


/*Dialog*/
.mat-dialog-container {background-color: rgb(218, 225, 225);color: #39393e;}
.mat-dialog-content{padding: 30px 20px!important;background-color: #fff;color: #303030;}
.mat-drawer-container{z-index: auto!important;}
.mat-drawer-content{z-index: auto!important;}

h1{
    font-family: "Nunito Sans", sans-serif !important;
}

h2,h3,h4,h5{
    font-family: "Nunito", sans-serif !important;
}
.nunito{
    font-family: "Nunito", sans-serif !important;
}
/*  
    font-family: "Poppins", sans-serif;
    font-family: "Nunito Sans", sans-serif;
    font-family: "Nunito", sans-serif;
*/
:host ::ng-deep .mat-select-panel {
    position: fixed; /* Para asegurarte de que esté posicionado correctamente */
    z-index: 10000; /* Un z-index alto para que esté por encima del modal */
  }

  /*INICIO  DE COLORES PARA TOMAR EN ICONOS Y FUENTES*/
.rojo{
    color: red !important;
}

.blanco{
    color: white !important;
}

.verde{
    color: green;
}

.gris{
    color: lightslategray;
}

.txtColor{
    color: #0D62A8;
}

.azul{
    color: blue !important;
}

.oro{
    color: #D3AF37;
    font-size: 20px;
    padding-right: 5px;
}

.negro{
    color:black !important;
}

.blanco{
    color: white !important;
}


.naranja{
    color: orange !important;
}

.amarillo{
    color: yellow !important;
}

.cdk-overlay-container{
    z-index: 1060 !important;
}

.sticky-top {
    z-index: 98 !important; 
}
.sticky-bottom{
    z-index: 98 !important; 
}
/* Cambiar el cursor a una manita en todos los enlaces <a> */
a {
    cursor: pointer;
}

/* Cambiar el cursor a una manita en todos los botones <button> */
button {
    cursor: pointer;
}


/* @media (max-width: 1367px) {
    .modal-backdrop {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 1041;
      width: 0;
      height: 100vh;
      background-color: #000;
    }
  }
 */
 .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1041;
    width: 0;
    height: 100vh;
    background-color: #000;
  }
  .modal{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.822); /* Fondo oscuro semitransparente */

    z-index: 1010;
    display: none; /* Oculto por defecto */
  }
  .borderbott{
    border-bottom-right-radius:20px;
    border-bottom-left-radius: 20px;
  }

  .bordertop{
    border-top-right-radius:20px;
    border-top-left-radius: 20px;
  }

  .bordertopLeft{
    border-top-left-radius: 20px;
  }

  .bordertopright{
    border-top-right-radius:20px;
  }
  .bgtable{
    /* background:#C7DAFF ; */
    background: #485f7d;
    color: #edeff2;
  }
  .btnFilter{
    background: #0a5481;
    color:#abdbef;
    font-weight: 500;
 }

 .spinner-container {
    background-color: #3f51b557; /* Color de fondo */
    border-radius: 50%; /* Hacer el fondo circular */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
/* [strokeWidth]="2" */
/* <mat-spinner [diameter]="60" 
class="spinner-container"></mat-spinner> */

.descripcion-truncada {
    display: -webkit-box;        /* Define un contenedor flexible en bloque */
    -webkit-box-orient: vertical;/* Establece la orientación vertical */
    -webkit-line-clamp: 2;       /* Limita el texto a 2 líneas */
    line-clamp: 2;  
    overflow: hidden;            /* Oculta el contenido que excede el límite */
    text-overflow: ellipsis;     /* Añade puntos suspensivos al final del texto truncado */
  }

  .fs-img{
    width: 50px ;
}

.fs-text{
    font-size:10px ;
}
.fs-txt-table{
    font-size:9px ;
}

@media (min-width: 480px){
    .fs-img{
        width: 50px;
    }
    .fs-text{
        font-size:11px ;
    }
    .fs-txt-table{
        font-size:10px ;
    }
}

@media(min-width:481px) and (max-width:768){
    .fs-img{
        width: 75%;
        height: auto;
    }
    
    .fs-text{
        font-size:12px ;
    }
    .fs-txt-table{
        font-size:11px ;
    }
}

  @media (min-width: 769px) {
    .fs-img {
        width: 100px; /* Las imágenes ocuparán el 50% del ancho del contenedor */
        height: auto; /* Mantiene la proporción */
    }
    .fs-text{
        font-size:14px ;
    }
    
    .fs-txt-table{
        font-size:12px ;
    }
}

.roundTopLeft{
    border-top-left-radius:10px;
}
.roundTopRigth{
    border-top-right-radius:10px;

}


.text-ellipsi {
    display: -webkit-box;            /* Usar contenedor flexible */
    display: box;       /* Usar contenedor flexible */
    -webkit-line-clamp: 2;           /* Navegadores WebKit */
    line-clamp: 2;                   /* Propiedad estándar para compatibilidad futura */
    -webkit-box-orient: vertical;    /* Orientar las líneas verticalmente */
    box-orient: vertical; /* Orientar las líneas verticalmente */
    overflow: hidden;            /* Ocultar texto que se desborde */
    text-overflow: ellipsis;     /* Mostrar tres puntos (...) */
    word-wrap: break-word;             /* Asegura que funcione con el ancho definido */
  }
  
  .btnAdd{
    background: #0085a0;
    color: #e7fbff;
  }

  .sinFlechas {
    appearance: none;        /* Propiedad estándar */
    -webkit-appearance: none; /* Chrome, Safari, Edge */
    -moz-appearance: none;    /* Firefox */
  }
  
  .sinFlechas::-webkit-outer-spin-button,
  .sinFlechas::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  
  .btn-close {
    filter: brightness(0) saturate(100%)!important;
}

.txtEcoBlue{
    color: #1C3D70 !important;
}

.txtEcoRed{
    color: #FE0000;
}

.custom-tooltip .shepherd-arrow:before {
    background: rgb(255, 255, 255);
  
    transform: rotate(45deg); /* más visible */
  }
  
  .custom-tooltip .shepherd-arrow {
    width: 16px;
    height: 16px;
  }

  .custom-tooltip[data-popper-placement^="bottom"] {
    margin-top: 20px !important;
  }